import { template as template_e967dc6bae444ab2a9c25e9b99a81a50 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_e967dc6bae444ab2a9c25e9b99a81a50(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
