import { template as template_34654e499e9e4bb385c9b2764f8e156e } from "@ember/template-compiler";
const FKText = template_34654e499e9e4bb385c9b2764f8e156e(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
