import { template as template_4fe39e786b194f3e9b6c95b01b08e01d } from "@ember/template-compiler";
const FKControlMenuContainer = template_4fe39e786b194f3e9b6c95b01b08e01d(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
